<template>
    <div class="tw-relative tw-overflow-auto scroll-bar tw-h-40">
        <table class="tw-full tw-text-sm tw-text-left tw-table-fixed">
            <thead class="tw-bg-white tw-py-2 tw-sticky tw-text-brand tw-text-sm tw-top-0">
                <tr>
                    <th scope="col" class="tw-px-4 tw-py-2 tw-w-1/2" v-for="cell in column" :key="cell.value">
                        {{ cell.label }}
                    </th>
                </tr>
            </thead>
            <tbody class="">
                <tr class="tw-bg-white tw-border-b" v-for="row in rows" :key="row.id">
                    <td v-for="cell in column" :key="cell.value" class="tw-px-4 tw-py-2">
                        <span v-if="cell.value === 'date' && tableType === 'docket'">{{ getFormattedTime(row[cell.value]) }} </span>
                        <span v-else-if="cell.value === 'date' && tableType === 'document'"
                            ><input type="checkbox" /> <span>{{ row[cell.value] }}</span></span
                        >
                        <span v-else>{{ row[cell.value] }}</span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import {DateTime} from "luxon";
export default {
    name: "uni-court-common-table",
    props: {
        column: {
            type: Array,
            default: () => [],
        },
        rows: {
            type: Array,
            default: () => [],
        },
        tableType: {
            type: String,
            default: "",
        },
    },
    data() {
        return {};
    },
    methods: {
        getFormattedTime(ts) {
            if (ts) {
                let date = new Date(ts).toISOString();
                date = DateTime.fromISO(date);
                return date.toFormat("dd MMM yyyy");
            }
            return "-";
        },
    },
};
</script>
