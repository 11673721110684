<template>
    <div class="tw-mb-2">
        <div class="headingBlock print-d-block tw-flex tw-items-center tw-py-1 tw-px-2 tw-mt-4 tw-justify-between tw-border tw-border-solid tw-rounded" :class="getStyleClass" @click="handleToggle(item, item.dataExist)">
            <div class="tw-text-lg tw-font-medium">{{ item.label }}</div>
            <div v-if="item.dataExist">
                <!-- :class="{ 'tw-rotate-180': adverseCard.detailsComplyExpanded }"  -->
                <svg xmlns="http://www.w3.org/2000/svg" class="tw-h-6 tw-w-6 tw-cursor-pointer tw-transition-all tw-transform tw-duration-300 hover:tw-text-brand" fill="none" viewBox="0 0 24 24" stroke="currentColor" :class="{'tw-rotate-180': showItemDetail}">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                </svg>
                <!-- <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="tw-h-6 tw-w-6 tw-cursor-pointer tw-transition-all tw-transform tw-duration-300 hover:tw-text-brand tw-rotate-180"><path data-v-1758274e="" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg> -->
            </div>
        </div>
        <div class="tw-p-2 headingBlock-sibling" v-if="showItemDetail && item.dataExist">
            <slot :name="item.value"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "uni-court-expand",
    components: {},
    props: {
        item: {
            type: Object,
            default: () => {},
        },
        expandAll: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            showItemDetail: false,
        };
    },
    computed: {
        getStyleClass() {
            return this.item.dataExist ? "tw-bg-blue-50 tw-border-blue-200 tw-cursor-pointer hover:tw-shadow-md" : "";
        },
    },
    methods: {
        handleToggle(item, dataExist) {
            if (dataExist) {
                this.showItemDetail = !this.showItemDetail;
                this.$emit("handleExpandAll", item.value, this.showItemDetail);
            } else {
                return;
            }
        },
    },
};
</script>
