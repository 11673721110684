import {DateTime} from "luxon";
import {EventBus} from "@/main.js";
import NlpAnalysisHighlighting from "@/components/nlp-analysis-highlighting";
import neoSwitch from "@/components/toggle-switch";
import {getBackgroundStatus} from "@/utils/monitoringTools.js";
import axios from "@/axios";
import {mapGetters} from "vuex";
import {FEATURES} from "@/utils/constants";
const ImagePlaceholder = () => import("@/components/image-placeholder");
const monitoringHistory = () => import("@/components/monitoring-history");

import Loader from "@/components/loader";
export default {
    name: "news-card",
    components: {
        "nlp-analysis-highlighting": NlpAnalysisHighlighting,
        neoSwitch,
        ImagePlaceholder,
        monitoringHistory,
        "neo-loader": Loader,
    },
    props: {
        result: {
            type: Object,
            default: {},
        },
        index: {
            type: Number,
            default: null,
        },
        parentIndex: {
            type: Number,
            default: null,
        },
        selected: {
            type: Number,
            default: null,
        },
        isResultExpanded: {
            type: Boolean,
            default: false,
        },
        isSavedLoading: {
            type: Object,
            default: {},
        },
        monitoring: {
            type: Boolean,
            default: false,
        },
        isAllRunQueries: {
            type: Boolean,
            default: false,
        },
        isOldVersion: {
            type: Boolean,
            default: false,
        },
        isOldVersionSelected: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isMonitoringTab: false,
            analysisStart: false,
            analsysisCards: [],
        };
    },
    computed: {
        ...mapGetters(["getProduct", "getSelectedToolQuery", "getCaseId", "getAnalyzerOptions", "getPermissions", "getAutoAnalyze"]),
        showAnalyser() {
            return this.getAnalyzerOptions.filter((res) => res.selected);
        },
        autoAnalyser() {
            return this.getAutoAnalyze;
        },
        getSavedClasses() {
            let classes = "";
            if (this.isSavedLoading.loading && this.isSavedLoading.loadingCardIndex === this.result.api_all_data && this.result.api_all_data.doc_id) classes += " tw-opacity-40 tw-cursor-not-allowed ";
            if (this.result.api_all_data.saved) classes += " tw-text-blue-700 tw-font-semibold  tw-text-xs tw-bg-blue-200 ";
            else classes += " tw-bg-blue-200 tw-text-blue-800 ";
            return classes;
        },
        getCardID() {
            if (this.parentIndex || this.parentIndex === 0) return `result-${this.parentIndex}.${this.index}`;
            return `result-${this.index}`;
        },
        getResultClasses() {
            let classes = "";
            if (this.isOldVersionSelected) {
                if (this.result.curr_index === this.selected && this.isOldVersion) classes += "tw-bg-blue-50 tw-border-blue-600 hover:tw-bg-blue-50";
                else classes += "hover:tw-bg-blue-50";
            } else {
                if (this.result.curr_index === this.selected && !this.isOldVersion) classes += "tw-bg-blue-50 tw-border-blue-600 hover:tw-bg-blue-50";
                else classes += "hover:tw-bg-blue-50";
            }
            // if (!this.result?.api_all_data?.history_count && !this.isOldVersion && this.isMonitoringTab)
            //     classes += ' tw-ml-2';
            if (this.selected || this.selected == 0) classes += "tw-flex-wrap";
            return classes;
        },
    },
    methods: {
        getBackgroundStatus,
        permissionExists(key) {
            return this.getPermissions.find((res) => FEATURES.tools[res] == key);
        },
        async startAnalyzer(cardData) {
            if (!this.analsysisCards.includes(cardData.api_all_data.doc_id)) {
                this.analsysisCards.push(cardData.api_all_data.doc_id);
            }
            let config = {};
            this.getAnalyzerOptions.forEach((res) => {
                if (this.permissionExists(res.key)) {
                    config[res.payload_name] = res.selected;
                }
            });
            cardData.api_all_data.analysis_status = "pending";
            cardData.api_all_data.analysis_remaining = 0;
            const url = `/case-analysis/${this.$route.params.id}/analysis`;
            try {
                await axios.post(url, {
                    record_count: 1,
                    tool: "news-tool",
                    queries: [
                        {
                            query_id: cardData.api_all_data.query_id,
                            doc_ids: [cardData.api_all_data.doc_id],
                        },
                    ],
                    config: config,
                    sources: [cardData.sourceTag == "News" ? "news" : "dowjonesfactiva"],
                });
            } catch (err) {
                this.$toast.error("Something went wrong.");
            }
        },
        getFormattedTime(ts) {
            let date = DateTime.fromISO(ts);
            return ts ? date.toFormat("dd MMM yyyy") : "-";
        },
        getResultFormattedTime(ts) {
            let date = DateTime.fromISO(ts);
            return date.toFormat("dd MMM yyyy");
        },
        expandNews() {
            EventBus.$emit("openShowMorePanel", {
                result: this.result,
                isOldVersion: this.isOldVersion,
                cardIndex: this.index,
            });
            // setTimeout(() => {
            //     document.getElementById(`result-card-${this.index}`)?.scrollIntoView();
            // }, 1000);
        },
        popOutWebpage() {
            EventBus.$emit("popOutWebpage", this.result);
        },
        async handleMonitoring() {
            // event.stopPropagation();
            let card = this.result;
            card.api_all_data.monitoring_status = !card.api_all_data.monitoring_status;
            // this.$forceUpdate();
            let query;
            if (this.isAllRunQueries) {
                query = this.result.query;
            } else {
                query = this.getSelectedToolQuery;
            }
            try {
                await axios.post("/data-monitoring", {
                    product_name: this.getProduct.name,
                    product_id: this.getProduct.key,
                    case_id: this.$route.params.id,
                    query: query,
                    tool: this.$route.params.toolName,
                    frequency: 7,
                    monitoring_type: "card",
                    inherit_product_settings: false,
                    inherit_product_id: "",
                    card_id: this.result.api_all_data.doc_id,
                    card_source: this.result.key,
                    status: card.api_all_data.monitoring_status ? "enabled" : "disabled",
                });
                this.$toast.success("Monitoring updated successfully !");
            } catch (error) {
                this.$toast.error("Something Went Wrong !");
            }
            EventBus.$emit("handleDone", {card, curr_index: card.curr_index});
        },
        // Save Result Card
        handleResultSaveData(result) {
            EventBus.$emit("handleNewsResultSave", result);
        },
        async handleDone({target}, oldVersion) {
            this.result.api_all_data.changes_viewed = !this.result.api_all_data.changes_viewed;
            EventBus.$emit("handleDone", {card: this.result, index: this.result.curr_index, oldVersion});
            this.$forceUpdate();
            let sources = [];
            if (this.result.key === "dowJones") {
                sources = this.result.api_all_data?.sources?.map((src) => src.toLowerCase());
            } else sources = [this.result.key].map((src) => src.toLowerCase());
            let payload = [
                {
                    doc_id: this.result.api_all_data.monitoring_id,
                    sources: sources,
                    cascaded: false,
                },
            ];
            await axios.post("/data-monitoring/mark-monitered-data-visited", {
                card_info: payload,
                visited: target.checked,
                case_id: this.getCaseId,
            });
            this.updateComparisonReferences(this.result);
        },

        updateComparisonReferences(data) {
            EventBus.$emit("updateMonitoringCascadingTimeline", {
                newData: data,
            });
        },

        handlePolling() {
            const interval = setInterval(async () => {
                const url = `/case-analysis/${this.$route.params.id}/analysis/docs`;
                const {data} = await axios.post(url, {tool: "news-tool", doc_ids: this.analsysisCards});
                if (data.some((res) => res.analysis_status == "completed")) {
                    EventBus.$emit("updateCategories", data);
                }
                if (!data.some((res) => res.analysis_status == "pending" || !res.analysis_status)) {
                    clearInterval(interval);
                }
            }, 2000);
        },
    },
    mounted() {
        this.isMonitoringTab = this.$route.name === "monitoring";
    },
    watch: {
        analsysisCards(newV, old) {
            this.handlePolling();
        },
    },
};
