<template>
    <common-table :column="column" :rows="rows" tableType="docket" />
</template>

<script>
import CommonTable from "./common-table.vue";
export default {
    name: "uni-court-docket",
    components: {
        CommonTable,
    },
    props: {
        docketData: {
            type: Array,
            default: () => [],
        },
    },

    computed: {
        rows() {
            return this.docketData;
        },
    },
    data() {
        return {
            column: [
                {label: "Date", value: "date"},
                {label: "Dock Entries", value: "details"},
            ],
        };
    },
    methods: {},
};
</script>
