<template>
    <common-table :column="column" :rows="rows" />
</template>

<script>
import CommonTable from "./common-table.vue";
export default {
    name: "uni-court-parties",
    components: {
        CommonTable,
    },
    props: {
        partyData: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            column: [
                {label: "Type", value: "type"},
                {label: "Party Name", value: "name"},
                // { label: "Representation", value: "representation" },
                {label: "Attorney", value: "attorney"},
            ],
        };
    },
    computed: {
        rows() {
            return this.partyData;
        },
    },
    mounted() {},
    methods: {},
};
</script>
