<!-- eslint-disable vue/no-parsing-error -->
<template>
    <div class="tw-mb-2">
        <div class="tw-flex tw-items-center tw-justify-between tw-py-2 tw-w-full">
            <a class="tw-flex tw-text-xl tw-text-brand tw-line-clamp-1 tw-no-underline" :class="[getPartyOrSnippets.url ? 'tw-cursor-pointer tw-pointer-events-auto focus-within:' : 'tw-pointer-events-none']" :href="getPartyOrSnippets.url || ''" :title="getPartyOrSnippets.url" target="_blank">{{ getPartyOrSnippets.text }}</a>
            <div class="tw-flex tw-text-right tw-px-2">
                <span id="close-button" class="tw-ml-auto tw-cursor-pointer hover:tw-text-brand-hover" @click="onClose">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" />
                        <path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z" />
                    </svg>
                </span>
            </div>
        </div>
        <div class="tw-flex-rows tw-items-center tw-py-2 tw-text-sm tw-w-full">
            <div class="tw-text-yellow-600 tw-py-2" v-if="content.jurisdiction">
                <span class="tw-bg-yellow-100 tw-px-1">{{ content.jurisdiction }}</span>
            </div>
            <div class="tw-text-red-500 tw-py-2" v-if="content.caseType">
                <span class="tw-bg-red-100 tw-pl-1">{{ content.caseType.trim() }}</span>
                <span class="tw-bg-red-100 tw-pr-1" v-if="content.caseTypeGroup">{{ " - " + content.caseTypeGroup.trim() }}</span>
            </div>
        </div>
        <div class="tw-flex tw-text-sm tw-py-2 tw-w-3/4">
            <div class="tw-text-gray-500 tw-w-1/3">Case Number</div>
            <div class="tw-px-2 tw-w-2/3">{{ content.caseNumber || "-" }}</div>
        </div>
        <div class="tw-flex tw-text-sm tw-py-2 tw-w-3/4">
            <div class="tw-text-gray-500 tw-w-1/3">Filling Date</div>
            <div class="tw-px-2 tw-w-2/3">{{ getDate || "-" }}</div>
        </div>
        <div class="tw-flex tw-text-sm tw-py-2 tw-w-3/4">
            <div class="tw-text-gray-500 tw-w-1/3">Status</div>
            <div class="tw-px-2 tw-w-2/3">{{ content.caseStatus || "-" }}</div>
        </div>
        <div class="tw-flex tw-text-sm tw-py-2 tw-w-3/4">
            <div class="tw-text-gray-500 tw-w-1/3">Judge</div>
            <div class="tw-px-2 tw-w-2/3">{{ content.judge || "-" }}</div>
        </div>

        <div v-if="isExpandALl" class="tw-text-blue-600 tw-cursor-pointer hover:tw-text-blue-400 tw-flex tw-w-full tw-px-2 tw-justify-end">
            <div @click="handleExpand('expand')" v-if="isExpand">
                Expand All
            </div>
            <div @click="handleExpand('collapse')" v-else>
                Collapse All
            </div>
        </div>
        <detail-list v-for="item in listMenu" :key="item.value" :item="item" @handleExpandAll="handleExpandAll" ref="detailList">
            <template #docket>
                <docket :docketData="docketData" />
            </template>
            <!-- <template #document>
                <document />
            </template> -->
            <template #parties>
                <parties :partyData="partyData" />
            </template>
            <!-- <template #attorney>
                <attorney />
            </template> -->
            <!-- <template #docket-history>
                <docket-history />
            </template> -->
        </detail-list>
    </div>
</template>

<script>
import {DateTime} from "luxon";
import DetailList from "./detailList.vue";
import Docket from "./ListItemsComponents/docket.vue";
import Parties from "./ListItemsComponents/parties.vue";
// import Attorney from "./ListItemsComponents/attorney.vue";
// import Document from "./ListItemsComponents/document.vue";
// import DocketHistory from "./ListItemsComponents/docket-history.vue";
export default {
    name: "uni-court-expand",
    components: {
        DetailList,
        Docket,
        // Document,
        Parties,
        // Attorney,
        // DocketHistory,
    },
    props: {
        content: {
            type: Object,
        },
        isResultExpanded: {
            type: Boolean,
        },
        isSavedLoading: {
            type: Object,
            default: () => {},
        },
        isOldVersionSelected: {
            type: Boolean,
            default: false,
        },
        isComparisonModeOn: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        content(newVal) {
            if (newVal && this.content?.key === "judyRecords") {
                this.getJudyRecordMenuData();
            }
            if (newVal && this.content?.key === "unicourt") {
                this.getUniCourtMenuData();
            }
        },
    },
    mounted() {
        if (this.content?.key === "judyRecords") {
            this.getJudyRecordMenuData();
        }
        if (this.content?.key === "unicourt") {
            this.getUniCourtMenuData();
        }
    },
    data() {
        return {
            listMenu: [
                {
                    label: "Docket",
                    value: "docket",
                    dataExist: false,
                },
                {
                    label: "Parties",
                    value: "parties",
                    dataExist: false,
                },
            ],
            partyInfo: null,
            docketData: [],
            partyData: [],
            attorneyData: [],
            // judgeData: [],
            expandedItems: [],
            isExpand: true,
        };
    },
    computed: {
        expandedCard() {
            return this.content;
        },
        isExpandALl() {
            return this.listMenu.some((el) => el.dataExist);
        },
        getPartyOrSnippets() {
            let snippet = {text: "", url: ""};
            if ((this.content.key === "judyRecords" && this.content.api_all_data.data.is_details_scraped) || this.content.key === "unicourt") {
                snippet.text = this.content.party;
                return snippet;
            } else {
                snippet.text = this.content.api_all_data.data.snippet;
                snippet.url = this.content.api_all_data.data.url;

                return snippet;
            }
        },
        getDate() {
            if (this.content.filingDate) {
                let date = new Date(this.content.filingDate).toISOString();
                date = DateTime.fromISO(date);
                return date.toFormat("dd MMM yyyy");
            }
            return "";
        },
    },
    methods: {
        onClose() {
            this.$emit("closeMoreInfo");
        },
        // get the party information
        getPartyInfo() {
            const partyInfo = this.content?.api_all_data?.party_info;
            if (partyInfo?.length < 0) {
                let formatData = {};
                partyInfo.forEach((item) => {
                    let hasKey = [item.type] in formatData;
                    if (!hasKey) {
                        formatData[item.type] = null;
                    }
                });
                for (const i in formatData) {
                    let parties = [];
                    partyInfo.forEach((item) => {
                        if (item.type === i) {
                            parties.push(item.name);
                        }
                    });
                    formatData[i] = parties;
                }
                this.partyInfo = formatData;
            }
        },

        handleExpand(type) {
            const {detailList} = this.$refs;
            const isAnyDataExist = this.listMenu.some((el) => el.dataExist);
            if (isAnyDataExist) {
                switch (type) {
                    case "expand": {
                        this.isExpand = false;
                        detailList.forEach((component) => {
                            component.showItemDetail = true;
                        });
                        break;
                    }
                    case "collapse": {
                        this.expandedItems = [];
                        this.isExpand = true;
                        detailList.forEach((component) => {
                            component.showItemDetail = false;
                        });
                        break;
                    }
                }
            }
        },
        // get the data for judy records menu items like Docket, Parties
        getJudyRecordMenuData() {
            if (this.content?.api_all_data.data?.is_details_scraped) {
                this.partyData = this.content?.api_all_data.data?.party_information;
                this.docketData = this.content?.api_all_data.data?.docket_entries;
                this.isDataExist();
            }
        },
        // get the data for uni court menu items like Docket, Parties
        getUniCourtMenuData() {
            this.docketData = this.content?.api_all_data?.docket_info.map((el) => ({date: el?.docket_entry_date, details: el?.text}));
            this.partyData = this.content?.api_all_data?.party_info.map((el) => ({type: el.source_party_role, name: el.name, attorney: ""}));
            this.isDataExist();
        },
        // is data exist or not
        isDataExist() {
            this.listMenu.forEach((item) => {
                item.dataExist = this.checkDataLength(item);
            });
        },
        checkDataLength(item) {
            if (item.value === "parties") {
                return this.partyData.length > 0;
            } else if (item.value === "docket") {
                return this.docketData.length > 0;
            } else {
                return false;
            }
        },
        handleExpandAll(item, flag) {
            if (flag) {
                this.expandedItems.push(item);
            } else {
                const index = this.expandedItems.indexOf(item);
                if (index > -1) {
                    this.expandedItems.splice(index, 1);
                }
            }
            const allItems = this.listMenu.map((el) => el.value);
            if (allItems.length === this.expandedItems.length) {
                this.isExpand = !this.expandedItems.every((val) => allItems.includes(val));
            } else {
                this.isExpand = true;
            }
        },
    },
};
</script>
